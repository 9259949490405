import React, { Component } from "react";
import AppComponent from "../AppComponent";
import Content from "../Content";
import CustomTabs from "../CustomTabs";

import { Table, Divider, Tabs, Alert } from 'antd';
const TabPane = Tabs.TabPane;

class ResearchProjectTable extends AppComponent {
  state = {
    endpoint: "/api/researchapprenticeship/project/",
    loading_projects: true,
    projects: [],
  };

  componentDidMount() {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.semester !== this.props.semester) {
      this.getData();
    };
  };

  getData = () => {
    this.setState({ loading_projects: true }, () => this.doGet(this.state.endpoint + "?semester=" + this.props.semesters[0], data => { this.setState({ projects: data, loading_projects: false }) }));
  };

  render() {
    const { loading_projects, projects } = this.state;

    const project_campuses = projects ? [...new Set(projects.map(el => el.campus))] : [];
    const submissionPeriodOpen = this.get_research_apprenticeship_application_status(this.props.semesters)


    const columns = [
      {
        title: "Project",
        key: "project",
        width: 300,
        render: (text, record, idx) => record.name,
      },
      {
        title: "Faculty",
        key: "faculty",
        width: 200,
        render: (text, record, idx) => this.print_full_instructor_list(record.faculty),
      },
      {
        title: "Attendance Mode",
        key: "attendance_mode",
        width: 200,
        sorter: (a, b) => a.attendance_mode.localeCompare(b.attendance_mode),
        render: (text, record, idx) => record.attendance_mode,
      },
    ];

    return (
      <Content
        {...this.props}
        title={"Research Apprenticeship Projects " + this.props.semester}
        breadcrumbs={[
          { link: "/student", text: "Students" },
          { text: "MS" },
          { text: "Research Apprenticeship" }
        ]}
      >
        {submissionPeriodOpen ? (
          projects?.length !== 0 ? (
            <>
              <p>
                Please review the projects available for this semester's Research
                Apprenticeship. To view a specific project's details such as
                description and prerequisites, please click the plus sign to expand
                each row.
              </p>
              <Divider orientation="left">Research Apprenticeship Projects</Divider>
              <CustomTabs {...this.props}>
                {project_campuses.map((campus) => (
                  <TabPane tab={this.print_campus(campus)} key={campus}>
                    <Table
                      dataSource={projects.filter((el) => el.campus === campus)}
                      columns={columns}
                      loading={loading_projects}
                      bordered={false}
                      pagination={false}
                      rowKey={(record) => record.id}
                      expandedRowRender={(record) => (
                        <div style={{ margin: 0 }}>
                          <p>
                            <strong>Description: </strong>
                            {record.description}
                          </p>
                          <p>
                            <strong>Prerequisites: </strong>
                            {record.prerequisites}
                          </p>
                        </div>
                      )}
                    />
                  </TabPane>
                ))}
              </CustomTabs>
            </>
          ) : (
            <Divider orientation="center">No projects to show</Divider>
          )
        ) : (
          <Alert message="Projects are visible when the application window is open." />
        )}
      </Content>

    );
  };
};

export { ResearchProjectTable };
